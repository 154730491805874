ion-content ion-toolbar {
  --background: transparent;
}

.cardTop {
  height: 40%;
  width: 100%;
  margin: 50px 0 0 0;
  border-radius: 0;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.content-main{
  background: rgb(2,0,36);
background: linear-gradient(167deg, rgba(2,0,36,1) 0%, rgba(82,80,80,1) 78%, rgba(48,53,54,0.9458158263305322) 100%);
}   