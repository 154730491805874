/* .ionsegmentNetflix {
    --background: transparent;
    --background-hover: rgba(var(--ion-color-primary-rgb, 56, 128, 255), 0.1);
    --background-activated: rgba(var(--ion-color-primary-rgb, 56, 128, 255), 0.16);
    --background-checked: #ffffff00;
    --color: var(--ion-color-dark-contrast);
    --color-checked: var(--ion-color-primary-contrast, #fff);
    --color-disabled: rgba(var(--ion-color-primary-rgb, 56, 128, 255), 0.3);
    --color-checked-disabled: rgba(var(--ion-color-primary-contrast-rgb, 255, 255, 255), 0.3);
    --border-color: #3880ff00;
    --indicator-color: transparent;
  } */
 
  .ionsegmentbuttonNetflix {
    font-size: 16px !important;
 
  }