/*
*Ionic React Full App (https://store.enappd.com/product/ionic-react-full-app-capacitor/)
*
* Copyright © 2019-present Enappd. All rights reserved.
*
* This source code is licensed as per the terms found in the
* LICENSE.md file in the root directory of this source tree.
*/

.content-bg {
    --background: #f4f5f8;
}

.card-basic {
    box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.6);
    ;
    border-radius: 9px;
    background-color: white !important;
    width: 425px;
}

.card-content {
    padding: 0 0 0 0;
}

.cardFooter {
    width: 100%;
    display: -webkit-box;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    background: linear-gradient(to bottom, #00416a00, #2126126b, #090513);
    padding-top: 16px;
    padding-bottom: 16px;

}

.card-present {
    box-shadow: unset;
    border-radius: 9px;
    background-color: rgb(30, 193, 43) !important;
}


.card-status-offline {
    display: flex;
    justify-content: flex-end;
    color: red;
    font-weight: 600;
}

.card-status-online {
    display: flex;
    justify-content: flex-end;
    color: green;
    font-weight: 600;
}

.card-title {
    color: black !important;
}
.card-desc{
    color: blue !important;
}

.cardTop {
    height: 40%;
    width: 100%;
    margin: 50px 0 0 0;
    border-radius: 0;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.member-img {
    width: 100%;
    height: 200px;
}

.price {
    font-size: 18px;
    float: left;
    margin: 3px;
    color: black;
}

.inline {
    font-size: 15px;
    margin-inline-end: 5px;
    text-decoration: line-through;
}

.row-member {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
}

.section-title {
    padding-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

.section-header {
    padding-bottom: 10px;
    padding-left: 10px;
    border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

.subtitle {
    font-size: 12px;
    color: black !important;
}

.badge {
    position: absolute;
    top: 10%;
    left: 0;
    background-color: rgba(12, 12, 12, .75);
    border-radius: 0;
}

@media(max-width: 450px) {
    .card-basic {
        box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.6);
        ;
        border-radius: 9px;
        background-color: white !important;
        width: 325px;
    }

    .modal {
        --background: rgb(81, 135, 228) !important;
        --backdrop-opacity: 0.7 !important;
        width: 100% !important;
    }

}

@media(min-width: 768px) {
    .modal {
        --background: rgb(81, 135, 228) !important;
        --backdrop-opacity: 0.7 !important;
        width: 100% !important;
        --border-radius: 20px;
    }

}