.mainFirebase {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

ion-card.card {
    --background: #bdbdbd;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.50);

}

@media screen and (min-width:768px) {
    ion-card.card {
        width: 500px;
        margin: auto;
    }
}

.input {
    background-color: rgb(240, 240, 240);
    border: 1px solid #a2a2a2;
    border-radius: 9px;
    font-size: .9em !important;
}

.white {
    color: white;
}

.small {
    font-size: 13px;
    color: gray;
}

.button-color {
    background-color: var(--ion-color-mytheme);
}

.login-page {
    --background: #455E7E;
}

.grid {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.img-logo {
    height: 100px;
    width: 300px;
}

.fireLogoLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btnText {
    padding: 10px;
    color: white;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 800;

}

.btn-transition {
    /* --background: linear-gradient(to right, #f57c00 14%, #ffca00 96%); */
    --background: #455E7E;
    --border-radius: 30px;
    height: 50px;
}

.segment-btn {
    font-weight: 800;
    font-size:16px;
    color: #455E7E
}